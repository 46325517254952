.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/bg_0_r.jpg");
  padding: 32px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
}

.background-0 {
  background-image: url("../../assets/bg_0_r.jpg");
}

.background-1 {
  background-image: url("../../assets/bg_1.jpg");
}
.background-2 {
  background-image: url("../../assets/bg_2.jpg");
}
.background-3 {
  background-image: url("../../assets/bg_3.jpg");
}
.background-4 {
  background-image: url("../../assets/bg_4.jpg");
}
.background-5 {
  background-image: url("../../assets/bg_5.jpg");
}
.background-6 {
  background-image: url("../../assets/bg_6.jpg");
}
.background-7 {
  background-image: url("../../assets/bg_7.jpg");
}
.header-card {
  display: flex;
  background: rgba(255, 255, 255, 0.85);
  align-items: center;
  justify-content: center;
  padding: 36px;
  text-align: center;
}

.line {
  height: 1px;
  width: 100%;
  background-color: #768192!important;
}

.home {
  display: block;
  overflow: auto;
  background-color: #5d8eaf;
}

.sidenav {
  min-height: calc(100vh - 106px);
  width: 330px;
  background-color: white;
  padding: 24px;
  transition: all 0.35s ease;
  position: relative;
  height: 100%;
}

.cm {
  height: 84px;
  padding-right: 8px;
}
/* .sidenav.collapsed {
  width: 0px;
  padding: 0px;
  opacity: 0;
  transition: all 0.35s ease;
} */

.custom-input {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 0 8px;
  min-width: 0;
  width: 100%;
}
.disabled {
  background-color: #e8e8e8;
  color: gray;
}

.input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.w-300 {
  width: 300px;
}

.cards-container {
  box-sizing: border-box;
  max-width: 1440px;
  width: 100%;
  padding-top: 32px;
  margin: auto;
}
