.graph-dropdown {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 50px;
  padding: 12px;
  border-radius: 12px;
}

.dropdown-wrapper {
  display: inline-block;
  position: relative;
}

.dropdown-wrapper:hover .graph-dropdown {
  display: block;
}

.graph-item:hover {
  font-weight: bold;
}
