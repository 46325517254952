.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 32px;
  width: 32px;
  border-width: 0px;
  border-radius: 16px;
  outline: none;
}
.hover i {
  color: lightslategray;
}

.hover:hover {
  background-color: lightgray;
}

.hover:focus {
  outline: none;
}
