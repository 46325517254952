.bottomNav {
  position: relative;
  left: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  z-index: 1;
  height: 53px;
  align-items: center;
  padding: 0 44px;
  justify-content: space-around;
}
