.mapFrame {
   width: 100%;
}

#projectMapFrame {
   height: 100%;
}

.drawer {
   height: 100%;
   width: 2%;
   background: #636f83 !important;
   float: left;
   z-index: 1;
}

.ol-popup-closer {
   text-decoration: none;
   position: absolute;
   top: 8px;
   right: 8px;
   cursor: pointer;
}
.ol-popup-closer:hover .mdi-close::before {
   font-weight: bold;

}

/* .projectSelect{
    position: absolute;
    right: 20px;
    width: 200px;
    top:10px;
    height:190px;
    padding:5px;
    color: #394b59;
    background-color: #f8f8f8;
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
    border-radius: 3px; 
    opacity:0.8;
    opacity:0.95;
    z-index: 10;
 } */

/* #popup{
   background-color: #f8f8f8;
   padding: 10px;
   border: 2px solid rgba(0,0,0,0.2);
   background-clip: padding-box;
   border-radius: 3px; 
   opacity:0.8;
} */

#popup {
   /* position: relative; */
   background: white;
   border-radius: 8px;
   box-shadow: 0 0 4px gray;
   background-clip: padding-box;
   color: black;
   padding: 16px;
   opacity: 0.85;
   font-size: 12px;
   width: 250px;
}