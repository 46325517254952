.linkbutton {
  max-width: 250;
  max-height: 35;
  margin-right: 12;
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: "middle";
  background-color: #321fdb;
  border-color: #321fdb;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 50rem;
}

.icon-button {
  width: 24px;
  height: 48px;
  background-color: transparent;
  color: #1976d2;
  transition: all 0.5s ease;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

.icon-button p {
  display: none;
  opacity: 0;
  transition: all 0.5s ease;
  font-weight: bold;
}

.icon-button:hover {
  width: 200px;
  background-color: #1976d2;
  color: white;
  transition: all 0.5s ease;
}

.icon-button:hover p {
  display: inline;
  opacity: 1;
  transition: all 0.5s ease;
}
