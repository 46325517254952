.grid-container {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

}

.half-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media screen and (max-width: 767px) {
    .half-grid-container {
        grid-template-columns: auto;
        
    }
    .grid-container {
        grid-template-columns: auto;
        
    }
}