.dropdown-custom:hover .dropdown-menu-custom {
  display: block;
  border-color: rgba(25, 118, 210, 0.2);
  border-radius: 16px;
  padding: 8px;
}

.dropdown-custom {
  border: 1px solid transparent;
}

.dropdown-custom:hover {
  border: 1px solid rgba(25, 118, 210, 0.2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-custom:hover::after {
  position: absolute;
  display: block;
  width: 100%;
  content: "";
  height: 8px;
  background-color: white;
  top: 32px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
}

.dropdown-custom {
  height: 37px;
}
