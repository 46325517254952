.text-center {
    text-align: center;
}

.width-200px {
    width: 200px;
}

.legend {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin-right: 8px;
}

.legend-red {
    background-color: red;
}

.legend-blue {
    background-color: blue;
}

.legend-orange {
    background-color: orange;
}

.legend-lightblue {
    background-color: lightblue;
}