.custom-table {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 16px;
  box-shadow: 0 0 12px lightgrey;
  background-color: white;
  border-color: #d8dbe0;
  z-index: 1;
  transition: all 0.35s ease;
}
