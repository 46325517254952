.two-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px
}

.one-two-grid {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 12px
}

.four-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px
}

.six-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
}

@media screen and (max-width: 767px) {
    .one-two-grid {
        grid-template-columns: 1fr 1fr;
    }
}