.custom {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 16px;
  box-shadow: 0 0 12px lightgrey;
  background-color: white;
  border-color: #d8dbe0;
  z-index: 1;
  transition: all 0.35s ease;
}
.custom-table {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 16px;
  box-shadow: 0 0 12px lightgrey;
  background-color: white;
  border-color: #d8dbe0;
  z-index: 1;
  transition: all 0.35s ease;
}
.custom:hover {
  box-shadow: 0 0 12px black;
  transform: scale(1.025);
}

.custom-task:hover {
  opacity: 0.7;
  cursor: pointer;
}
.custom-pdf {
  background-color: white;
  border: 2px solid red;
  padding: 4px;
  border-radius: 4px;
}
.custom-equalizer {
  background-color: white;
  padding: 4px;
  border-radius: 4px;
  border: 2px solid green;
}
.custom-image {
  background-color: white;
  border-radius: 4px;
  padding: 4px;
  border: 2px solid navy;
}
.custom-results {
  background-color: white;
  border-color: #d8dbe0;
}
.custom-alias {
  background-color: #3c91e6;
  border-color: #3c91e6;
}
.custom-notes {
  background-color: #07beb8;
  border-color: #07beb8;
}
.custom-bioresults {
  background-color: white;
  border-color: #d8dbe0;
}
.custom-biosamples {
  background-color: #424874;
  border-color: #424874;
}
.add {
  border-radius: 0px;
  width: 70px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  position: absolute;
  /* z-index: -1; */
  right: 60px;
}

.custom-lg {
  display: grid;
  padding-top: 12px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 767px) {
  .custom-lg {
    grid-template-columns: auto;
  }
}
.card-body-custom {
  padding: 0;
}

.border-bottom {
  padding-bottom: 12px;
  border-bottom: 1px solid grey;
}

.custom-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
