.page-content {
  margin: 15px;
  overflow: visible;
}

.tableContainer {
  max-width: calc(100vw - 330px);
  padding: 0 32px;
  width: 100%;
  transition: all 0.35s ease;
}

.expanded {
  max-width: calc(100vw - 75px);
}