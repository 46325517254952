.go-button {
    height: 38px;
}

.custom-grid-wrapper {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr;
    gap: 12px;
    width: 75%;
    vertical-align: bottom;
    padding-bottom: 16px;
}

@media screen and (max-width: 1024px) {
    .custom-grid-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .custom-grid-wrapper {
        grid-template-columns: auto;
        width: 100%;
    }
}